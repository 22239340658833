<template>
  <div class="notifications">
    <div
      class="simpleInfoPanel"
      v-loading="loading"
    >
      <ul class="filtroPanel">
        <li class="w-input-time">
          <span>读取状态：</span>
          <el-select
            v-model="params.state"
            placeholder="请选择读取状态"
            popper-class="w-block-select-down"
          >
            <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li class="w-input-time">
          <span>消息类型：</span>
          <el-select
            popper-class="w-block-select-down"
            v-model="params.typeId"
            placeholder="请选择消息类型"
          >
            <el-option
              v-for="item in typeOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </li>
        <li class="w-input-time">
          <span>接收日期：</span>
          <el-date-picker
            popper-class="black-el-date-picker"
            v-model="params.date"
            @change="handleDate"
            type="daterange"
            range-separator="-"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </li>
        <li>
          <el-button
            class="btnPrimary btnCommon"
            @click="toSearch"
          >查询</el-button>
        </li>
      </ul>
    </div>
    <div class="simpleInfoPanel">
      <el-table
        :data="tableData"
        border
        ref="multipleTable"
        @selection-change="handleSelectionChange"
        class="tbsWrap"
      >
        <el-table-column
          type="selection"
          width="55"
        > </el-table-column>
        <el-table-column
          prop="title"
          label="消息标题"
        > </el-table-column>
        <el-table-column
          prop="date"
          label="接收时间"
        > </el-table-column>
        <el-table-column
          prop="status"
          label="状态"
        >
          <template slot-scope="scope">
            {{ scope.row.status === 0 ? "未读" : "已读" }}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-popconfirm
              title="确定要删除此消息？"
              placement="right-end"
              @confirm="deleteItem([scope.row.id])"
            >
              <a slot="reference">删除</a>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <div style="width: 98%; margin: 20px auto">
        <el-checkbox
          v-model="isCheckAll"
          @change="allChecked($event, tableData)"
        >全选</el-checkbox>
        <span
          class="textBtn"
          @click="handleStatus"
        >设为已读</span>
        <div
          class="w-page-sty fr"
          style="margin: 0"
        >
          <el-pagination
            background
            layout="total, prev, pager, next"
            :total="params.total"
            :page-size="params.pageSize"
            :current-page="params.pageNum"
            @current-change="handlePages"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import dayjs from 'dayjs'
import { mapGetters } from 'vuex'
export default {
  name: 'notifications',
  components: {},
  data () {
    return {
      form: {
        status: '',
        type: '',
        date: ''
      },
      statusOptions: [
        {
          value: '',
          label: '所有状态'
        },
        {
          value: 1,
          label: '已读信息'
        },
        {
          value: 0,
          label: '未读信息'
        }
      ],
      typeOptions: [
        {
          value: '',
          label: '所有类型'
        },
        {
          value: '1',
          label: '招商任务提醒'
        },
        {
          value: '2',
          label: '权益分配提醒'
        },
        {
          value: '3',
          label: '权益回收提醒'
        },
        {
          value: '4',
          label: '联系特权提醒'
        },
        {
          value: '5',
          label: '合同到期预警'
        },
        {
          value: '6',
          label: '出租率预警'
        },
        {
          value: '7',
          label: '销售坪效预警'
        },
        {
          value: '8',
          label: '租金收缴率预警'
        },
        {
          value: '9',
          label: '欠费金额预警'
        },
        {
          value: '10',
          label: '欠费账期预警'
        },
        {
          value: '11',
          label: '招商任务预警'
        }
      ],
      tableData: [],
      params: {
        endDate: '',
        startDate: '',
        state: '',
        typeId: '',
        total: 0,
        pageNum: 1,
        pageSize: 20,
        date: []
      },
      currentChecked: [],
      loading: true,
      isCheckAll: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    })
  },
  mounted () {
    this.openSocket()
  },
  methods: {
    openSocket () {
      let socket = null
      const that = this
      if (typeof WebSocket === 'undefined') {
        console.log('您的浏览器不支持WebSocket')
      } else {
        console.log('您的浏览器支持WebSocket')
        // 实现化WebSocket对象，指定要连接的服务器地址与端口  建立连接
        // 等同于socket = new WebSocket("ws://localhost:8888/xxxx/im/25");
        // var socketUrl="${request.contextPath}/im/"+$("#userId").val();
        console.log('user', this.user)
        var socketUrl =
          this.$winshangDomain +
          '/imserver/' +
          this.user.userId +
          '/0/xxzx'
        const WsProtocol = process.env.NODE_ENV === 'development' ||
          window.location.href.indexOf('http://') > -1 ? 'ws' : 'wss'
        socketUrl = socketUrl.replace('https', WsProtocol).replace('http', WsProtocol)
        console.log(socketUrl)
        if (socket != null) {
          socket.close()
          socket = null
        }
        socket = new WebSocket(socketUrl)
        socket.onopen = function () {
          socket.send(this.token)
        }
        // 打开事件
        socket.onopen = function () {
          console.log('websocket已打开')
          // socket.send("这是来自客户端的消息" + location.href + new Date());
        }
        // 获得消息事件
        socket.onmessage = function (msg) {
          console.log(msg.data)
          // 发现消息进入    开始处理前端触发逻辑
          that.getNotificationsList()
        }
        // 关闭事件
        socket.onclose = function () {
          console.log('websocket已关闭')
        }
        // 发生了错误事件
        socket.onerror = function () {
          console.log('websocket发生了错误')
        }
      }
    },
    deleteItem (idArr) {
      this.axios
        .post(api.updateInformationMsgIsDelete, { ids: idArr })
        .then(
          (res) => {
            this.$message({ message: res.data.msg, type: 'success' })
            this.getNotificationsList()
          },
          (rej) => {
            console.log(rej)
          }
        )
        .catch((err) => {
          console.log(err)
        })
    },
    allChecked (isCheck, list) {
      this.$refs.multipleTable.toggleAllSelection()
    },
    toggleSelection (rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange (val) {
      this.currentChecked = val
    },
    handleStatus () {
      if (this.currentChecked.length) {
        const idArr = []
        for (let i = 0; i < this.currentChecked.length; i++) {
          idArr.push(this.currentChecked[i].id)
        }
        this.axios
          .post(api.updateInformationMsgViewed, {
            ids: idArr
          })
          .then((res) => {
            console.log(res)
            if (res.data.code === 0) {
              this.getNotificationsList()
              this.$message({ message: res.data.msg, type: 'success' })
              this.isCheckAll = false
            }
          })
      } else {
        console.log('no checked')
      }
    },
    toSearch () {
      this.params.pageNum = 1
      this.getNotificationsList()
    },
    getNotificationsList () {
      this.loading = true
      this.axios
        .post(api.getEarlyWarningInformation, this.params)
        .then(
          (res) => {
            if (res.data.code === 0) {
              const resData = res.data.data
              this.tableData = resData.dataList.map((item) => {
                item.status = item.state
                return item
              })
              this.params.total = resData.total
            }
            this.loading = false
            console.log(res)
          },
          (rej) => {
            console.log(rej)
          }
        )
        .catch((err) => {
          console.log(err)
        })
    },
    handlePages (val) {
      this.params.pageNum = val
      this.getNotificationsList()
    },
    handleDate (val) {
      if (val) {
        this.params.startDate = dayjs(val[0]).format()
        this.params.endDate = dayjs(val[1]).format()
      } else {
        this.params.startDate = ''
        this.params.endDate = ''
      }
    }
  }
}
</script>

<style scoped lang="stylus">
.notifications
  overflow hidden
  .simpleInfoPanel
    width 99%
    margin 15px auto
    .el-table
      background-color #272930
  .filtroPanel
    .el-input__inner
      width 86% !important
  .textBtn
    margin-left 15px
    color #fff !important
    font-size 14px
    cursor pointer
.notifications>>>
  .el-table--border td, .el-table--border th, .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed
    border-right none
</style>
